<template>
    <AcceptInvite />
 </template>
 
 <script>
   import { defineAsyncComponent } from 'vue'
 export default {
     name: 'Accept Invite',
     components: {
        AcceptInvite: defineAsyncComponent( () => import('@/components/AcceptInvite.vue')),
       },
 
 }
 </script>